.why-choose-right h1 {
    /* color: #f8ae27; */
    color: white;
    margin-top: 25%;
    text-decoration: underline;
    text-decoration-color: red;
}

/* .why-choose-right h2 {
    color: #f8ae27;
} */

.why-choose-right p {
    color: white;
}

.why-choose {
    padding-bottom: 75px;
}

img.why-choose-image {
    width: 100%;
    margin-top: 25%;
}

.why-choose {
    padding-bottom: 100px;
    margin-top: 70px;
    background-color: #141414;
}

@media (max-width: 600px) {
    img.why-choose-image {
        margin-top: 10%;
    }

    .why-choose {
        padding-bottom: 30px;
        margin-top: 30px;
        background-color: #141414;
    }

    .why-choose-right h1 {
        margin-top: 10%;
        /* width: 93%; */
        font-size: 25px;
        text-align: center;
    }

    .why-choose-right p {
        color: white;
        text-align: center;
    }
}