section.cullinary {
    /* background: black; */
    /* background: url("E:/react-typescritp/my-app/public/assets/images/About-us/Group 37.png") left bottom no-repeat, #141414 right bottom no-repeat; */
    background-position: right;
    padding-bottom: 100px;
    padding-top: 100px;
    margin-top: 13%;
}

.cullinary-second-div {
    margin-top: 20%;
}

.cullinary-second-div h1 {
    color: white;
    /* border-bottom: 2px solid #f8ae27; */
    /* width: 75%; */
    text-decoration: underline;
    /* text-decoration-color: #f8ae27; */
    text-decoration-color: red;
}

.cullinary-second-div h2 {
    color: #f8ae27;
}

img.pepperfry-image {
    width: 29%;
    float: right;
    margin-right: 30%;
    margin-top: -123px;
}

.cullinary-second-div p {
    color: white;
}

@media (max-width: 600px) {
    section.cullinary {
        background: none;
        margin-top: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    img.cullinary-first-image {
        margin: 0px 10%;
        display: none;
    }

    .cullinary-second-div h1 {
        /* width: 76%; */
        text-align: center;
        font-size: 25px;
    }

    .cullinary-second-div {
        margin-top: 30px;
    }

    .cullinary-second-div h2 {
        color: #f8ae27;
        text-align: center;
    }

    .cullinary-second-div p {
        color: white;
        text-align: center;
    }
}

@media (min-width: 1367px) {
    img.pepperfry-image {
        float: right;
        margin-right: 30%;
        margin-top: -123px;
        width: 27%;
    }

    section.cullinary {
        margin-top: 18%;
    }
}