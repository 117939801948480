@keyframes moveUpDown {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }
}

.nav-home {
    --blur: 1.75rem;
    --box-blur: calc(0.5 * var(--blur));
    --glow: var(--color);
    /* --size: 12rem; */
    width: 50% !important;
    margin: 0px 25%;
    align-items: center;
    border-radius: 30px;
    border: 4px solid currentColor;
    /* box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.15), 0 0 0 2px rgba(0, 0, 0, 0.15), inset 0 0 var(--box-blur) var(--glow), 0 0 var(--box-blur) var(--glow); */
    /* color: var(--color, white); */
    display: inline-flex;
    flex-direction: column;
    /* font-family: system-ui, sans-serif; */
    height: auto;
    justify-content: space-around;
    /* padding: 1rem; */
    /* width: var(--size); */
    background: transparent;
    padding-top: 15px;
    padding-bottom: 15px;

    >* {
        margin: 0;
        padding: 0;
    }

    .number {
        font-weight: 700;
    }

    .symbol {
        font-size: 4rem;
        font-family: 'Neon Glow';
        text-shadow: 0 0 var(--blur) var(--glow);
    }

    &.he {
        --color: #FCEAAC;
        filter: saturate(175%);
    }

    &.ne {
        --color: #FDA802;
        filter: brightness(110%);
    }

    &.ar {
        --color: #E555C7;
        filter: brightness(125%);
    }

    &.kr {
        --color: #B7E7F7;
        filter: saturate(200%);
    }

    &.xe {
        --color: #C4C4C6;
        filter: brightness(105%);
    }
}

.explore-button {
    background-color: black;
    border: 2px solid transparent;
    border-image: linear-gradient(to right, red, white 50%, red);
    border-image-slice: 1;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    outline: none;
    transition: background-color 0.3s, color 0.3s, border-image 0.3s;
    background-origin: border-box;
    background-clip: content-box, border-box;
    margin: 0px 24%;
    width: 53%;
}

.explore-button:hover {
    color: white;
    border-image: linear-gradient(to right, red, white 50%, red), linear-gradient(to right, red, white 50%, red);
    box-shadow: 0 0 10px 5px red;
}

.btn-gradient-2 {
    background: linear-gradient(black, black) padding-box, linear-gradient(to right, red, white 50%, red) border-box;
    border-radius: 30px;
    border: 4px solid transparent;
    color: white !important;
    margin: 4% 32%;
    width: 37%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.btn-gradient-2:hover {
    color: white;
    border-image: linear-gradient(to right, red, white 50%, red), linear-gradient(to right, red, white 50%, red);
    box-shadow: 0 0 10px 5px red;
}

section.history-section {
    background: #141414;
    padding-bottom: 45px;
    margin-top: -143px;
    padding-top: 70px;
    overflow: hidden;
}

button.history-button {
    border: 1px solid red;
    background: transparent;
    color: white;
    padding-left: 34px;
    font-size: 33px;
    padding-right: 34px;
    font-family: auto;
    padding-top: 5px;
    margin: 15px 28%;
    padding-bottom: 5px;
}

.history-para p {
    text-align: center;
    color: white;
    font-size: 14px;
}

img.history-image-1 {
    margin-top: 32%;
    z-index: 666;
    filter: blur(0px);
    width: 60%;
    border-radius: 30px;
    position: relative;
    top: 0px;
    left: 0px;
}

img.history-image-2 {
    margin-left: 0;
    width: 75%;
    margin-top: 0;
    position: relative;
    top: 0;
    height: 75%;
    left: 15%;
    border-radius: 30px;
}

.history-para {
    margin-top: 8%;
}

.history-image-div {
    margin-top: 50px;
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
}

.history-date h1 {
    float: right;
    margin-right: 80px;
}

.history-date h1 {
    float: right;
    margin-right: 80px;
    /* background-image: url("E:/react-typescritp/my-app/public/assets/images/Ellipse 105.png"); */
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 33px;
    color: white;
    padding: 25px;
    position: absolute;
    top: 12px;
    right: 6%;
    animation: moveUpDown 5s ease-in-out infinite;
}

img.chefs-rotate-image {
    position: absolute;
    top: 86px;
    right: 9%;
    width: 70px;
    margin-right: 80px;
    transform: rotate(20deg);
    animation: moveUpDown 5s ease-in-out infinite;
}

.history-date {
    position: relative;
}

/* button.explore-button {
    background: black;
    color: #731702;
    border: 1px solid #f29829;
    width: 44%;
    font-size: 23px;
    padding-top: 5px;
    background: #f29829;
    padding-bottom: 5px;
    margin: 0% 28%;
    font-weight: bold;
    border-radius: 30px;
    font-family: auto;
} */

.history-section h4 {
    text-align: center;
    color: white;
}

p.history-sub-head {
    text-align: center;
    color: white;
}

h1.history-header {
    text-align: center;
    color: white;
    font-family: auto;
    text-decoration: underline;
    /* text-decoration-color: #f8ae27; */
    text-decoration-color: red;
}

@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* .history-section .col-md-6.right-animation {
    animation: slideInRight 1s ease-in-out;
    overflow-x: hidden;
} */

.right-come {
    animation: slideInRight 1s ease-in-out;
    overflow-x: hidden;
}

.left-come {
    animation: slideInLeft 1s ease-in-out;
    overflow: hidden;
}

/* .history-section .col-md-6.left-animation {
    animation: slideInLeft 1s ease-in-out;
    overflow: hidden;
} */

/**** Media Query ****/

@media (max-width: 600px) {
    .history-date h1 {
        margin-right: 0;
    }

    /* img.history-image-2 {
        margin-left: -100px;
        width: 65%;
        height: 200px;
        margin-top: 0;
        object-fit: contain;
    }

    img.history-image-1 {
        margin-top: 32%;
        z-index: 666;
        position: relative;
        width: 45%;
        height: 200px;
        object-fit: contain;
    } */

    /* h1.history-header {
        margin: 23px 29%;
    } */

    .history-para {
        margin-top: 0%;
    }

    section.history-section {
        padding-top: 45%;
    }
}