h1.chefs-header {
    text-align: center;
    color: white;
    font-family: auto;
    /* border: 1px solid red; */
    padding-top: 15px;
    padding-bottom: 6px;
    /* border-bottom: 2px solid #f8ae27; */
    /* margin: 8px 39%; */
    text-decoration: underline;
    /* text-decoration-color: #f8ae27; */
    text-decoration-color: red;
}

section.chefs-section {
    background-color: #141414;
    padding-top: 45px;
    padding-bottom: 70px;
}

.over-owl-caption {
    padding-left: 5px;
    background: transparent;
    text-align: center;
    padding-right: 5px;
    color: white;
    margin-top: 25px;
}

.owl-captions p {
    margin-bottom: 0;
    color: #e4c58f;
}

.owl-nav.disabled {
    display: block !important;
}

button.owl-next {
    position: absolute;
    top: 31%;
    right: -25px;
    /* background: #f8ae27 !important;
    border: 1px solid #f8ae27 !important; */
    background: red !important;
    border: 1px solid red !important;
    width: 41px !important;
    font-size: 15px !important;
}

.owl-item:hover img.img.owl-chef-image {
    /* margin-top: -30px; */
    transform: scale(1.2);
}

.owl-item img.img.owl-chef-image {
    transition: transform .5s, filter 0.5s ease-in-out;
}

/* .owl-item:hover .owl-captions {
    margin-top: -92px;
    transition: ease-in 250ms;
    transition: ease-out 250ms;
} */

button.owl-prev {
    position: absolute;
    top: 31%;
    left: -25px;
    /* background: #f8ae27 !important;
    border: 1px solid #f8ae27 !important; */
    background: red !important;
    border: 1px solid red !important;
    width: 41px !important;
    font-size: 15px !important;
}

button.owl-prev span {
    font-size: 29px;
    font-weight: bold;
    /* color: #ad502b; */
    color: white;
}

button.owl-next span {
    font-size: 29px;
    font-weight: bold;
    /* color: #ad502b; */
    color: white;
}

.owl-carousel .owl-item img {
    display: block;
    width: 72%;
    height: 68%;
    object-fit: cover;
    margin: 0px 16%;
    border-radius: 65%;
    margin-top: 20%;
}

.owl_image.img_fluid {
    border: 1px solid;
    background: #201d1d;
    border-radius: 28px;
    width: 73%;
    margin: 0px 16%;
    height: 295px;
}

.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    display: none;
}

/**** Media Query ****/

@media (min-width: 320px) and (max-width: 400px) {
    .owl-carousel .owl-item img {
        width: 72%;
        height: 62%;
    }
}

@media (min-width: 400px) and (max-width: 600px) {
    .owl-carousel .owl-item img {
        width: 72%;
        height: 66%;
    }
}

@media (max-width: 600px) {
    /* h1.chefs-header {
        margin: 45px 22%;
    } */

    button.owl-prev {
        left: 0px;
    }

    button.owl-next {
        right: 0;
    }

    .owl_image.img_fluid {
        margin: 0px 13%;
    }
}

@media (min-width: 600px) and (max-width: 800px) {
    .owl-carousel .owl-item img {
        display: block;
        width: 72%;
        height: 79%;
        object-fit: cover;
        margin: 0px 16%;
        border-radius: 65%;
        margin-top: 7%;
    }

    .owl_image.img_fluid {
        border: 1px solid;
        background: #201d1d;
        border-radius: 28px;
        width: 73%;
        margin: 0px 16%;
        height: 175px;
    }

    button.owl-prev {
        position: absolute;
        top: 22%;
        left: -25px;
        background: #f8ae27 !important;
        border: 1px solid #f8ae27 !important;
        width: 41px !important;
        font-size: 15px !important;
    }

    button.owl-next {
        position: absolute;
        top: 22%;
        right: -25px;
        background: #f8ae27 !important;
        border: 1px solid #f8ae27 !important;
        width: 41px !important;
        font-size: 15px !important;
    }

    h4.product-name {
        font-size: 15px;
    }

    .strength-1 ul li {
        margin-top: 23px;
        text-align: center;
    }

    .strength-2 ul li {
        margin-top: 23px;
        text-align: center;
    }
}

@media(min-width: 800px) and (max-width: 1024px) {
    .owl-carousel .owl-item img {
        width: 72%;
        height: 54%;
        margin-top: 29%;
    }
}

@media (min-width: 1367px) {
    .owl-carousel .owl-item img {
        height: 68%;
        margin: 20% 19% 0;
        width: 61%;
    }
}