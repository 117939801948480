.our-team-right h1 {
    color: white;
    margin-top: 25%;
    /* border-bottom: 2px solid #f8ae27; */
    /* width: 30%; */
    text-decoration: underline;
    /* text-decoration-color: #f8ae27; */
    text-decoration-color: red;
}

.our-team-right h2 {
    color: #f8ae27;
}

.our-team-right p {
    color: white;
}

img.our-team-image {
    width: 85%;
    margin-top: 25%;
    border-radius: 10px;
}

section.our-team {
    margin-top: 70px;
    background-color: #141414;
}

@media (max-width:600px) {
    img.our-team-image {
        margin: 0px 8%;
        display: none;
    }

    .our-team-right h1 {
        /* width: 30%; */
        margin-top: 10%;
        font-size: 25px;
        text-align: center;
    }

    .our-team-right h2 {
        color: #f8ae27;
        text-align: center;
    }

    section.our-team {
        margin-top: 30px;
        background-color: #141414;
    }

    .our-team-right p {
        color: white;
        text-align: center;
    }
}

@media(min-width: 1367px) {
    .our-team-right h1 {
        color: #fff;
        margin-top: 37%;
        text-decoration: underline;
        text-decoration-color: red;
    }
}